<template>
  <v-app v-if="roleId">
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view
      v-if="loading"
    />
  </v-app>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
      loading: false,
    }),
    computed: {
      ...mapState({
        roleId: state => state.authentication.roleId,
      }),
      ...mapGetters({
        getVerifierCustomerId: 'verifier/getVerifierCustomerId',
        getVerifierId: 'verifier/getVerifierId',
        getVerifierLongDescription: 'verifier/getVerifierLongDescription',
        getVerifierLowValue: 'verifier/getVerifierLowValue',
        getVerifierRvDomain: 'verifier/getVerifierRvDomain',
        getVerifierShortDescription: 'verifier/getVerifierShortDescription',
        getObjectVerifierById: 'verifier/getObjectVerifierById',
      }),
      ...mapActions({
        callGetOfferVerifier: 'verifier/getVerifiersForOffer',
        callGetCustomerVerifier: 'verifier/getVerifiersForCustomer',
        callGetOrderVerifier: 'verifier/getVerifiersForOrder',
        callGetUserVerifier: 'verifier/getVerifiersForUser',
        callGetRegionVerifier: 'verifier/getVerifiersForRegion',
        callGetBranchVerifier: 'verifier/getVerifiersForBranch',
        callGetDeliveryVerifier: 'verifier/getVerifiersForDelivery',
        callGetDeliveryOrderVerifier: 'verifier/getVerifiersForDeliveryOrder',
      }),
    },
    async mounted () {
      await this.loginWithToken()
      await this.callGetOfferVerifier
      await this.callGetCustomerVerifier
      await this.callGetOrderVerifier
      await this.callGetUserVerifier
      await this.callGetRegionVerifier
      await this.callGetBranchVerifier
      await this.callGetDeliveryVerifier
      await this.callGetDeliveryOrderVerifier
      this.loading = true
    },
    methods: {
      async loginWithToken () {
        await this.$store.dispatch('authentication/doLoginWithToken')
          .catch(() => {
            this.$router.replace(this.$route.query.redirect || '/auth/login')
          })
      },
    },
  }
</script>

/*
 This domains need to be implemented
"REGION_STATUS"
"STATUS"
"ORDER_STATUS"
"DEALER_STATUS"
"GENDER"
"BRANCH_STATUS"
"COUNTRY"
"WAREHOUSE_STATUS"
"PAYMENT_STATUS"
"ADDRESS_TYPE"
"DELIVERY_STATUS"
"CUSTOMER_STATUS"
"OFFER_STATUS"
"DELIVERY_ORDER_STATUS"
"USER_STATUS"
*/
